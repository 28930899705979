import { css } from "@emotion/react";

export const Loader = () => {
  return <div className="loader" css={styles} />;
};

const styles = css`
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 0.25em solid #3272f1;
  border-top-color: transparent;

  animation: spinner 1.5s linear infinite;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;
