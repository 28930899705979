import { NextPage } from "next";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useState } from "react";

import { css } from "@emotion/react";

import { ExternalLink } from "@/components/atoms/ExternalLink";
import { Link } from "@/components/atoms/Link";
import { Loader } from "@/components/atoms/Loader";
import { Logo } from "@/components/atoms/Logo";
import { SignInForm } from "@/components/molecules/SignInForm";
import { expertApi } from "@/lib/driver/NewspicksExpertApi";
import { MeJsonV2 } from "@/lib/driver/NewspicksExpertApi";
import { logger } from "@/lib/logger";
import { colors, mq } from "@/lib/styleTokens";
import { PageRoot } from "@/ui/layout/PageRoot";
import { ReCaptchaPolicy } from "@/ui/layout/ReCaptchaPolicy";

const Index: NextPage = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageRoot title="エキスパートログイン">
      <div css={page}>
        <main css={containerStyles}>
          <div css={logo}>
            <Logo />
          </div>
          <div css={formContainer}>
            <div css={form}>
              {isLoading && (
                <div css={loader}>
                  <Loader />
                </div>
              )}
              <div>
                <SignInForm setIsLoading={setIsLoading} />
              </div>

              <div css={formFooter}>
                <p>
                  <ExternalLink
                    href="https://newspicks.expert/faq"
                    query={router.query}
                    target="_blank"
                    textDecoration="none">
                    お困りですか？よくあるご質問はこちら
                  </ExternalLink>
                </p>
                <p>
                  <Link href="/signUp" textDecoration="none">
                    アカウントをお持ちでない方は新規登録へ
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div
            css={css`
              margin-bottom: 16px;
            `}>
            <ReCaptchaPolicy />
          </div>
          <div css={extModules}>
            <ExternalLink
              href={"https://newspicks.expert/ext-modules"}
              target={"_blank"}
              color={colors.white}
              hoverColor={colors.white}
              style={linkStyle}>
              利用者に関する情報の外部送信について
            </ExternalLink>
          </div>
        </footer>
      </div>
    </PageRoot>
  );
};

export default Index;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { req } = ctx;
  const token = req.cookies["npex-token"];

  if (!token) {
    return {
      props: {},
    };
  }

  let expert: MeJsonV2 | null = null;
  try {
    expert = await expertApi.findMeV2FromServer(token);
    if (expert) {
      return {
        redirect: {
          destination: "/mypage",
          permanent: false,
        },
      };
    }
  } catch (e) {
    logger.error(`Authentication failed: ${e}`);
    ctx.res.setHeader("Set-Cookie", "npex-token=; Path=/; Max-Age=0;");
  }

  return {
    props: {},
  };
};

const page = css`
  background-image: url("/images/background.jpg");
  background-size: cover;
  background-position: bottom;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 850px) {
    padding: 80px 0;
  }
`;

const containerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 850px) {
    flex: 0;
    flex-direction: column;
    gap: 32px;
  }

  ${mq.mobile} {
    gap: 24px;
  }
`;

const logo = css`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 850px) {
    flex: 0;
  }

  ${mq.mobile} {
    width: 180px;
  }
`;

const formContainer = css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 850px) {
    flex: 0;
  }
`;

const form = css`
  position: relative;
  width: 490px;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;

  ${mq.mobile} {
    width: 90%;
    padding: 40px 16px;
  }
`;

const formFooter = css`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${colors.text.accent};

  ${mq.mobile} {
    text-align: center;
  }
`;

const loader = css`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const extModules = css`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const linkStyle = css`
  font-size: 12px;
  text-decoration-line: underline;
  line-height: 100%;
`;
