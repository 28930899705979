import React from "react";

import { SerializedStyles, css } from "@emotion/react";

import { mq } from "@/lib/styleTokens";

type Props = {
  type?: keyof typeof types;
  variant?: headingLevel;
  children: React.ReactNode;
  testid?: string;
  style?: SerializedStyles;
};

type headingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export const PaperTitle: React.FC<Props> = ({ children, type = "primary", variant = "h1", testid, style }) => {
  const Component = variant;
  return (
    <Component css={[types[type], style]} data-testid={testid}>
      {children}
    </Component>
  );
};

const primary = css`
  display: flex;
  align-items: center;
  gap: 32px;
  font-size: 24px;
  font-weight: bold;
  padding: 0 40px;

  ${mq.mobile} {
    padding: 0 16px;
  }
`;

const secondary = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  color: #222;
  text-align: center;

  ${mq.mobile} {
    font-size: 24px;
  }
`;

const types = { primary, secondary };
