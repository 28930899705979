import React from "react";

import { css } from "@emotion/react";

import { ExternalLink } from "@/components/atoms/ExternalLink";
import { colors } from "@/lib/styleTokens";

export const ReCaptchaPolicy: React.FC = () => {
  return (
    <p css={footer}>
      ※本サイトは、reCAPTCHAによって保護され、
      <ExternalLink
        href="https://policies.google.com/privacy"
        target="_blank"
        color={colors.white}
        hoverColor={colors.white}
        textDecoration="underline">
        Googleのプライバシーポリシー
      </ExternalLink>
      と
      <ExternalLink
        href="https://policies.google.com/terms"
        target="_blank"
        color={colors.white}
        hoverColor={colors.white}
        textDecoration="underline">
        利用規約
      </ExternalLink>
      が適用されます
    </p>
  );
};

const footer = css`
  color: ${colors.white};
  text-align: center;
  font-size: 12px;

  @media (max-width: 850px) {
    margin: 24px 24px 0;
  }
`;
