import { ComponentProps, forwardRef } from "react";

import { css } from "@emotion/react";

import { colors as globalColors } from "@/lib/styleTokens";

export type InputProps = ComponentProps<"input"> & {
  isInvalid?: boolean;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input({ isInvalid, readOnly, ...props }, ref) {
  return (
    <input
      {...props}
      ref={ref}
      css={[style, isInvalid && invalidStyle]}
      tabIndex={readOnly ? -1 : undefined}
      readOnly={readOnly}
    />
  );
});

const style = css`
  width: 100%;
  height: 52px;
  padding: 13px 16px;
  font-size: 16px;
  outline: none;
  -webkit-appearance: none;

  border: 1px solid var(--color-border-input);
  border-radius: 4px;
  color: var(--color-font);
  :-webkit-autofill {
    box-shadow: 0 0 0 25px rgb(255, 255, 255) inset !important;
    -webkit-text-fill-color: #222 !important;
  }
  ::placeholder {
    color: var(--color-font-placeholder);
  }

  :hover,
  :focus {
    border: 1px solid ${globalColors.blue600};
  }

  :invalid {
    border: 1px solid ${globalColors.red600};
    background: #fff;
  }

  :read-only {
    background: ${globalColors.gray50};
    border: 1px solid ${globalColors.gray200};
    color: var(--color-font);

    :hover {
      pointer-events: none;
      border: 1px solid var(--color-border-input);
    }
  }
`;

const invalidStyle = css`
  border: 1px solid ${globalColors.red600};
`;
