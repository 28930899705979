import { useRouter } from "next/router";
import { Fragment, useState } from "react";

import { css } from "@emotion/react";

import { Alert } from "@/components/atoms/Alert";
import { Button } from "@/components/atoms/Button";
import { ExternalLink } from "@/components/atoms/ExternalLink";
import { Link } from "@/components/atoms/Link";
import { PaperTitle } from "@/components/atoms/PaperTitle";
import { authApi } from "@/lib/driver/NewspicksExpertAuthApi";
import { NoMyPageError, WAFError } from "@/lib/Errors";
import { colors } from "@/lib/styleTokens";
import { assertIsString } from "@/lib/utils/assert";

import { AuthInput } from "./AuthInput";

const getRedirectPathname = (pathname: string | undefined) => {
  const defaultRedirectPath = "/mypage";
  const isValidRedirectPath = pathname?.startsWith("/") && !pathname.startsWith("//");

  if (!pathname || !isValidRedirectPath) {
    return defaultRedirectPath;
  }

  return pathname;
};

type Props = {
  setIsLoading: (value: boolean) => void;
};

export const SignInForm: React.FC<Props> = ({ setIsLoading }) => {
  const router = useRouter();
  const [mailAddress, setMailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<unknown>(Error);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await authApi.signIn(mailAddress, password);
      if (router.query.redirect) {
        assertIsString(router.query.redirect);
      }
      const redirect = getRedirectPathname(router.query.redirect);
      router.push(decodeURIComponent(redirect));
    } catch (e) {
      if (e instanceof WAFError) {
        router.push("/403");
        return;
      }
      setIsLoading(false);
      setIsError(true);
      setError(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} css={formStyle} className="sign-in">
      <PaperTitle type="secondary">
        <Fragment>
          <div>NewsPicksアカウントで</div>
          <div>マイページにログイン</div>
        </Fragment>
      </PaperTitle>
      {isError &&
        (error instanceof NoMyPageError ? (
          <Alert css={alert} className="error">
            ログイン情報設定が完了していません
            <br />
            <Link href="/signUp">新規設定はこちら</Link>
          </Alert>
        ) : (
          <Alert css={alert} className="error">
            メールアドレスもしくはパスワードが間違っています
          </Alert>
        ))}

      <AuthInput {...{ isError, mailAddress, password, setMailAddress, setPassword }} />

      <p css={passwordNotice}>
        <ExternalLink
          href="https://newspicks.expert/faq/mail_and_pw"
          query={router.query}
          target="_blank"
          textDecoration="none">
          メールアドレス/パスワードが分からない方はこちら
        </ExternalLink>
      </p>

      <Button type="submit">NewsPicksアカウントでログイン</Button>
    </form>
  );
};

const formStyle = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const alert = css`
  padding: 0;
`;

const passwordNotice = css`
  color: ${colors.text.accent};
`;
