import React from "react";

import { css } from "@emotion/react";

import { mq } from "@/lib/styleTokens";

export const Logo = () => {
  return <img date-testid="logo" css={styles} src="/images/logo-white.svg" alt="NewsPicks Expert" />;
};

const styles = css`
  ${mq.mobile} {
    width: 100%;
  }
`;
