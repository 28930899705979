import React, { ReactNode } from "react";

import { css } from "@emotion/react";

import { colors } from "@/lib/styleTokens";
import { DataTestId } from "@/types/elements";
type Props = {
  className: string;
  dataTestId?: DataTestId;
  children: ReactNode;
};

export const Alert: React.FC<Props> = ({ className, dataTestId = null, children }) => {
  return (
    <div className={className} css={styles} data-testid={dataTestId}>
      {children}
    </div>
  );
};

const styles = css`
  width: 100%;
  color: ${colors.red600};
  font-size: 16px;
  font-weight: 700;
  padding: 12px 0px;
  border-radius: 8px;
  white-space: pre-wrap;
`;
