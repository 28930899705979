import { css } from "@emotion/react";

import { Input } from "@/components/atoms/Input";
import { mq } from "@/lib/styleTokens";
import { colors } from "@/lib/styleTokens";

type Props = {
  isError: boolean;
  mailAddress: string;
  password: string;
  setMailAddress: (mailAddress: string) => void;
  setPassword: (password: string) => void;
};

export const AuthInput: React.FC<Props> = ({ isError, mailAddress, password, setMailAddress, setPassword }) => {
  return (
    <div css={style}>
      <label className="mail-address-label" css={title}>
        メールアドレス
        <Input
          className="mail-address"
          placeholder="taro.yamada@uzabase.com"
          type="email"
          isInvalid={isError}
          value={mailAddress}
          onChange={(e) => setMailAddress(e.target.value)}
        />
      </label>
      <label className="password-label" css={title}>
        パスワード
        <Input
          className="password"
          placeholder="******"
          type="password"
          isInvalid={isError}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
    </div>
  );
};

const style = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const title = css`
  font-size: 16px;
  color: ${colors.gray800};
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;

  ${mq.mobile} {
    font-size: 15px;
  }
`;
